import { Link } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Gallery from '../components/gallery';
import PostListing from '../components/postListing';

import thumb01 from '../assets/images/thumbs/01.jpg';
import thumb02 from '../assets/images/thumbs/02.jpg';
import thumb03 from '../assets/images/thumbs/03.jpg';
import thumb04 from '../assets/images/thumbs/04.jpg';
import thumb05 from '../assets/images/thumbs/05.jpg';
import thumb06 from '../assets/images/thumbs/06.jpg';

import full01 from '../assets/images/fulls/01.jpg';
import full02 from '../assets/images/fulls/02.jpg';
import full03 from '../assets/images/fulls/03.jpg';
import full04 from '../assets/images/fulls/04.jpg';
import full05 from '../assets/images/fulls/05.jpg';
import full06 from '../assets/images/fulls/06.jpg';

const DEFAULT_IMAGES = [
    {
        id: '1',
        source: full01,
        thumbnail: thumb01,
        caption: 'Photo 1',
        description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'
    },
    {
        id: '2',
        source: full02,
        thumbnail: thumb02,
        caption: 'Photo 2',
        description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'
    },
    {
        id: '3',
        source: full03,
        thumbnail: thumb03,
        caption: 'Photo 3',
        description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'
    },
    {
        id: '4',
        source: full04,
        thumbnail: thumb04,
        caption: 'Photo 4',
        description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'
    },
    {
        id: '5',
        source: full05,
        thumbnail: thumb05,
        caption: 'Photo 5',
        description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'
    },
    {
        id: '6',
        source: full06,
        thumbnail: thumb06,
        caption: 'Photo 6',
        description: 'Lorem ipsum dolor sit amet nisl sed nullam feugiat.'
    }
];

interface BlogPost {
    node: object;
}

interface IndexPageProps {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            };
        };
        allMarkdownRemark: {
            edges: Array<BlogPost>;
        };
    };
}

export default class extends React.Component<IndexPageProps, {}> {
    constructor(props: IndexPageProps, context: any) {
        super(props, context);
    }

    public render() {
        const {
            data: {
                site: {
                    siteMetadata: { title: siteTitle, description: siteDescription }
                },
                allMarkdownRemark: { edges: blogPosts }
            }
        } = this.props;

        return (
            <Layout>
                <Helmet>
                    <title>{siteTitle}</title>
                    <meta name="description" content={siteDescription} />
                </Helmet>
                <section id="one">
                    {blogPosts.map(({ node }) => (
                        <PostListing key={node.id} post={node} />
                    ))}
                    {/* <header className="major">
                            <h2>
                                Ipsum lorem dolor aliquam ante commodo
                                <br />
                                magna sed accumsan arcu neque.
                            </h2>
                        </header>
                        <p>
                            Accumsan orci faucibus id eu lorem semper. Eu ac
                            iaculis ac nunc nisi lorem vulputate lorem neque
                            cubilia ac in adipiscing in curae lobortis tortor
                            primis integer massa adipiscing id nisi accumsan
                            pellentesque commodo blandit enim arcu non at amet
                            id arcu magna. Accumsan orci faucibus id eu lorem
                            semper nunc nisi lorem vulputate lorem neque
                            cubilia.
                        </p>
                        <ul className="actions">
                            <li>
                                <a href="#" className="button">
                                    Learn More
                                </a>
                            </li>
                        </ul>
                    </section>

                    <section id="two">
                        <h2>Recent Work</h2>

                        <Gallery
                            images={DEFAULT_IMAGES.map(
                                ({
                                    id,
                                    source,
                                    thumbnail,
                                    caption,
                                    description
                                }) => ({
                                    source,
                                    thumbnail,
                                    caption,
                                    description
                                })
                            )}
                        />

                        <ul className="actions">
                            <li>
                                <a href="#" className="button">
                                    Full Portfolio
                                </a>
                            </li>
                        </ul> */}
                </section>
            </Layout>
        );
    }
}

export const pageQuery = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                title
                description
            }
        }
        allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "MMMM DD, YYYY")
                        path
                        tags
                    }
                    snippet
                    html
                    excerpt(pruneLength: 280)
                }
            }
        }
    }
`;
