import React from 'react';
import Link from 'gatsby-link';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PostTitle, PostBody, PostDate } from './common/posts';

const PostListingArticle = styled.article`
    border-top: 1px solid #eee;
    padding: 1em 0;
`;

const PostTitleListing = styled(PostTitle)`
    a {
        text-decoration: none;
        cursor: pointer;
        border: none;
    }
`;

const PostBodyForListing = styled(PostBody)`
    p {
        margin: 0;
    }

    a {
        border: none;
    }
`;

const PostListing = ({
    post: {
        frontmatter: { title, date, path, tags },
        snippet
    }
}) => (
    <PostListingArticle>
        <PostTitleListing as="h3">
            <Link to={path}>{title}</Link>
        </PostTitleListing>
        <PostDate>
            <span className="icon fa-calendar" style={{ marginRight: '0.5em' }} />
            {date}
        </PostDate>
        <PostBodyForListing>
            <div
                dangerouslySetInnerHTML={{
                    __html: snippet
                }}
            />
            <div style={{ textAlign: 'right' }}>
                <Link to={path} state={{ more: true }}>
                    <span className="icon fa-book"> Read…</span>
                </Link>
            </div>
        </PostBodyForListing>
    </PostListingArticle>
);

PostListing.propTypes = {
    post: PropTypes.object
};

export default PostListing;
